import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";
import pick from "pick-random-weighted";
import { createContext, useContext, useEffect, useState } from "react";
import { createFunnelEvent } from "../config/Analytics";
import { getValue } from "../config/Lead";

const ExperimentContext = createContext();

export const useExperiment = () => useContext(ExperimentContext);

const allExp = {
  default: 0.0,
  phone_redux_2_3_25: 0.5,
  borderless_2_3_25: 0.5,
};

const experimentConfig = Object.keys(allExp).map(function (key) {
  return [key, allExp[key]];
});

export const EXPERIMENT_ENUM = "expid";

export const ExperimentContextProvider = ({ children }) => {
  const router = useRouter();

  const [experiment, setExperiment] = useState("default");
  const [loaded, setLoaded] = useState(false);
  const [rocketMortgage, setRocketMortgage] = useState(false);

  useEffect(() => {
    setRocketMortgage(
      router.query.step === "rocket" && getValue("country") === "CA"
    );
  }, [router.query.step, experiment]);

  useEffect(() => {
    const chosenExperiment = router.query.force_experiment_group
      ? router.query.force_experiment_group
      : process.browser
      ? window.localStorage.getItem(EXPERIMENT_ENUM)
      : getCookie(EXPERIMENT_ENUM);

    const defaultExperiment =
      allExp[chosenExperiment] !== undefined &&
      allExp[chosenExperiment] !== null
        ? chosenExperiment
        : "default";

    const cookieExp = getCookie(EXPERIMENT_ENUM);
    const sessionUUID =
      window.localStorage.getItem("sessionUUID") || getCookie("sessionUUID");

    if (router.query.utm_sitelink === "t") {
      createFunnelEvent({
        eventType: "experiment_default_sitelink",
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: defaultExperiment,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, defaultExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", defaultExperiment);
      setLoaded(true);
      return;
    }

    if (!chosenExperiment) {
      const exp = pick(experimentConfig);
      setExperiment(exp);
      createFunnelEvent({
        eventType: "experiment_chosen",
        step: router.query.step || "landing",
        leadType: router.query.leadType,
        details: {
          exp,
          session_uuid: sessionUUID,
        },
      });

      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, exp, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", exp);
      setExperiment(exp);
    } else if (router.query.force_experiment_group) {
      createFunnelEvent({
        eventType: "experiment_group_forced",
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: router.query.force_experiment_group,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, chosenExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });
      window.localStorage.setItem("expid", chosenExperiment);
      setExperiment(chosenExperiment);
    } else if (chosenExperiment !== defaultExperiment) {
      createFunnelEvent({
        eventType: "experiment_default",
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: defaultExperiment,
          chosenExperiment,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, defaultExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", defaultExperiment);
      setExperiment(defaultExperiment);
    } else {
      setExperiment(chosenExperiment);
    }

    if (!cookieExp && chosenExperiment) {
      createFunnelEvent({
        eventType: "reset_experiment_cookie_to_chosen",
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          session_uuid: sessionUUID,
        },
      });
      setCookie(EXPERIMENT_ENUM, chosenExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });
    }

    setLoaded(true);
  }, [router.query]);

  return (
    <ExperimentContext.Provider
      value={{
        experiment,
        rocketMortgage,
        setExperiment,
        loaded,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};

export default ExperimentContext;
